import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

const monthsShortDot = [
  'ene.',
  'feb.',
  'mar.',
  'abr.',
  'may.',
  'jun.',
  'jul.',
  'ago.',
  'sep.',
  'oct.',
  'nov.',
  'dic.',
];
const monthsShort = [
  'ene',
  'feb',
  'mar',
  'abr',
  'may',
  'jun',
  'jul',
  'ago',
  'sep',
  'oct',
  'nov',
  'dic',
];
import { makeYearAwareCalendar } from './makeYearAwareCalendar';

const timeFormat = function (date: any) {
  return date.hours() === 1 ? '[a la]' : '[a las]';
};

moment.locale(window.locale, {
  months: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  monthsShort(m, format) {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (/-MMM-/.test(format)) {
      return monthsShort[m.month()];
    } else {
      return monthsShortDot[m.month()];
    }
  },
  weekdays: [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ],
  weekdaysShort: ['dom.', 'lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'],
  weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'LT:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY LT',
    LLLL: 'D [de] MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'D/M/AAAA',
    LT: 'H:mm',
  },
  calendar: makeYearAwareCalendar({
    sameDay() {
      return `[hoy] ${timeFormat(this)} LT`;
    },
    nextDay() {
      return `[mañana] ${timeFormat(this)} LT`;
    },
    lastDay() {
      return `[ayer] ${timeFormat(this)} LT`;
    },
    nextWeek() {
      return `llll ${timeFormat(this)} LT`;
    },
    lastWeek() {
      return `llll ${timeFormat(this)} LT`;
    },
    sameYear() {
      return `llll ${timeFormat(this)} LT`;
    },
    sameElse() {
      return `ll ${timeFormat(this)} LT`;
    },
  }),
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
  ordinalParse: /\d{1,2}º/,
  ordinal: () => '%dº',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
